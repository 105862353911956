<template>
  <div>
    <v-tabs
      v-model="tab"
      fixed-tabs
      background-color="primary"
      dark
      class="mb-5"
    >
      <v-tab>Profil</v-tab>
      <v-tab>Fotografia</v-tab>

      <v-tab>Stav účtu</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="12"
            md="10"
          >
            <validation-observer
              ref="observer"
              v-slot="{ invalid }"
            >
              <v-form @submit.prevent="update">
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-card class="mb-3">
                      <v-card-title>Základné údaje</v-card-title>
                      <v-card-text>

                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Prihlasovacie meno"
                              rules="required"
                            >
                              <v-text-field
                                v-model="form.username"
                                :error-messages="errors"
                                label="Prihlasovacie meno"
                                name="login"
                                prepend-icon="mdi-account"
                                type="text"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >

                            <validation-provider
                              v-slot="{ errors }"
                              name="Email"
                              vid="email1"
                              :rules="{required: true, email: true}"
                            >
                              <v-text-field
                                v-model="form.email"
                                :error-messages="errors"
                                label="Email"
                                name="email"
                                prepend-icon="mdi-email"
                                type="text"
                                @click.right.prevent
                                @copy.prevent
                                @paste.prevent
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Email znovu"
                              rules="confirmed:email1"
                            >

                              <v-text-field
                                v-model="form.email2"
                                :error-messages="errors"
                                label="Email znovu"
                                name="email2"
                                prepend-icon="mdi-email"
                                type="text"
                                @click.right.prevent
                                @copy.prevent
                                @paste.prevent
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Heslo"
                              rules="min:10"
                              vid="password1"
                            >

                              <v-text-field
                                id="password"
                                v-model="form.password"
                                :error-messages="errors"
                                label="Heslo"
                                name="password"
                                prepend-icon="mdi-lock"
                                :type="show1 ? 'text' : 'password'"
                                @click:append="show1 = !show1"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                @click.right.prevent
                                @copy.prevent
                                @paste.prevent
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Heslo znovu"
                              rules="confirmed:password1"
                            >

                              <v-text-field
                                id="password"
                                v-model="form.password2"
                                :error-messages="errors"
                                label="Heslo znovu"
                                name="password2"
                                prepend-icon="mdi-lock"
                                :type="show2 ? 'text' : 'password'"
                                @click:append="show2 = !show2"
                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                @click.right.prevent
                                @copy.prevent
                                @paste.prevent
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Meno"
                              rules="required"
                            >

                              <v-text-field
                                v-model="form.first_name"
                                :error-messages="errors"
                                label="Meno"
                                name="first_name"
                                prepend-icon="mdi-account"
                                type="text"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Priezvisko"
                              rules="required"
                            >

                              <v-text-field
                                v-model="form.last_name"
                                :error-messages="errors"
                                label="Priezvisko"
                                name="last_name"
                                prepend-icon="mdi-account"
                                type="text"
                              />
                            </validation-provider>
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-select
                              v-model="form.gender"
                              label="Pohlavie"
                              prepend-icon="mdi-account"
                              :items="selects"
                            />
                          </v-col>

                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card>
                      <v-card-title>Detaily</v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            align="center"
                          >
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-large
                                  v-bind="attrs"
                                  v-on="on"
                                  color="error"
                                  outlined
                                >
                                  DÔLEŽITÉ
                                </v-btn>
                              </template>
                              <v-card><v-card-text>
                                  <p>Ak si vyplníš aj IBAN a overíš sa prostredníctvom platby z banky, tak budeš mať viac výhod oproti tým, ktorí sa takýmto spôsobom neoveria. A zároveň nás môžeš aj trochu podporiť.</p>

                                  <p>Pýtaš sa načo je nám číslo tvojho účtu (IBAN)?
                                    Na overenie tvojej identity prostredníctvom bankového účtu. Prostredníctvom prijatej platby z tvojho účtu dokážeme jednoducho spárovať teba ako skutočnú osobu s sobou, ktorá bude vystupovať na týchto platformách a tým výrazne znížime a eliminujeme vytváranie falošných identít, ktoré by mohli negatívne ovplyvňovať komentáre v diskusiách. A o to nám tu predsa ide. Aby sme tu mali čo najkorektnejšie informácie od skutočných osôb a nie od imaginárnych trolov.</p>

                                  <p> Číslo tvojho účtu a ani číslo účtu nikoho sa nedá zneužiť. Len heslá a pin kódy k účtu sa dajú zneužiť.
                                    A TIE OD TEBA NEŽIADAME! A nikdy ani nebudeme.</p>

                                  <p> Veď už len plynárne, elektrárne, či mobilní operátori majú zverejnené svoje čísla IBAN na každej faktúre a aj na ich webových sídlach a to oni na účtoch majú podstatne viac peňazí ako my. Hocikde dávaš číslo tvojho účtu a keď niekomu pošleš peniaze, tak to číslo účtu prijímateľ vidí.</p>

                                  <p>My tu chceme využiť moderné informačné technológie na to aby sme AUTOMATIZOVANE, teda bez nároku na ďalšie personálne zaťaženie, overili tvoju unikátnu identitu. Prebieha to tak, že ak sa v našom systéme objaví platba z účtu, ktorý sa zhoduje s tým číslom účtu IBAN, ktoré uvedieš sem pri registrácií, bude táto platba priradená k tebe. Aj vo svojom profile sa Ti táto platba AUTOMATIZOVANE pripočíta k celkovej sume, ktorú si zaslal/a v náš prospech a to aj opakovane – teda je to kumulatívny údaj. Náš systém komunikuje s bankovým systémom a platby sa spárujú. Preto Ťa prosíme aby si správne uviedol/a číslo tvojho účtu a pri každej platbe používal/a ako VS (variabilný symbol) aj tvoje unikátne ID (identifikačné číslo), ktoré sa Ti vytvorí automaticky po tejto úspešnej registrácií a budeš ho vidieť vo svojom profile.</p>

                                  <p> Ďakujeme za pochopenie.</p>

                                  <p>Boris Wagner</p>
                                </v-card-text></v-card>
                            </v-menu>
                          </v-col>

                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="IBAN"
                              vid="iban1"
                              :rules="{
          required: false,
          iban: '^([0-9a-zA-Z]{4}\\s){5}[0-9a-zA-Z]{4}$'}"
                            >
                              <v-text-field
                                :error-messages="errors"
                                v-model="form.bank_account"
                                label="IBAN - NEPOVINNÉ"
                                name="bank_account"
                                prepend-icon="mdi-bank"
                                type="text"
                                v-mask="'SK## #### #### #### #### ####'"
                                @click.right.prevent
                                @copy.prevent
                                @paste.prevent
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="IBAN"
                              :rules="{
          required: false,
          confirmed: 'iban1'}"
                            >
                              <v-text-field
                                :error-messages="errors"
                                v-model="form.bank_account2"
                                label="IBAN znovu - NEPOVINNÉ"
                                name="bank_account2"
                                prepend-icon="mdi-bank"
                                type="text"
                                v-mask="'SK## #### #### #### #### ####'"
                                @click.right.prevent
                                @copy.prevent
                                @paste.prevent
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Telefónne číslo"
                              vid="phone1"
                              :rules="{
          required: true,
          phone: '^\\+421[1-9]\\d{8}$'}"
                            >
                              <v-text-field
                                v-model="form.phone_number"
                                :error-messages="errors"
                                label="Telefónne číslo"
                                name="phone_number"
                                prepend-icon="mdi-phone"
                                type="text"
                                required
                                @click.right.prevent
                                @copy.prevent
                                @paste.prevent
                              ></v-text-field>

                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Telefónne číslo"
                              :rules="{confirmed: 'phone1'}"
                            >
                              <v-text-field
                                v-model="form.phone_number2"
                                :error-messages="errors"
                                label="Telefónne číslo znovu"
                                name="phone_number2"
                                prepend-icon="mdi-phone"
                                type="text"
                                required
                                @click.right.prevent
                                @copy.prevent
                                @paste.prevent
                              ></v-text-field>

                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Ulica"
                              rules="required"
                            >

                              <v-text-field
                                v-model="form.address_street"
                                :error-messages="errors"
                                label="Ulica"
                                name="address_street"
                                prepend-icon="mdi-map-marker"
                                type="text"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Číslo"
                              rules="required"
                            >

                              <v-text-field
                                v-model="form.address_number"
                                :error-messages="errors"
                                label="Číslo"
                                name="address_number"
                                prepend-icon="mdi-map-marker"
                                type="text"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Mesto/Obec"
                              rules="required"
                            >

                              <v-autocomplete
                                v-model="form.address_town"
                                :error-messages="errors"
                                :items="itemsTown"
                                :search-input.sync="searchTown"
                                item-text="name"
                                item-value="name"
                                label="Mesto/Obec"
                                placeholder="Mesto/Obec"
                                prepend-icon="mdi-map-marker"
                                @change="townChanged($event)"
                                return-object
                                hide-no-data
                              ></v-autocomplete>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="PSČ"
                              rules="required"
                            >

                              <v-text-field
                                v-model="form.address_zip_code"
                                :error-messages="errors"
                                label="PSČ"
                                name="address_zip_code"
                                prepend-icon="mdi-map-marker"
                                type="text"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="form.address_okres"
                              disabled
                              label="Okres"
                              name="address_okres"
                              prepend-icon="mdi-map-marker"
                              type="text"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="form.address_kraj"
                              disabled
                              label="Kraj"
                              name="address_kraj"
                              prepend-icon="mdi-map-marker"
                              type="text"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="form.address_country"
                              disabled
                              label="Krajina"
                              name="address_country"
                              prepend-icon="mdi-flag"
                              type="text"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    :disabled="invalid"
                    @click="update"
                  > Uložiť </v-btn>
                </v-card-actions>
              </v-form>
            </validation-observer>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row justify="center">
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="2"
          >

            <v-img
              v-if="form.photo"
              class="my-5"
              :src="form.photo"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-file-input
                  v-model="photo_file"
                  accept="image/*"
                  label="Fotografia - sem vlož svoju fotografiu, ktorá sa bude zobrazovať pri tvojom profile. Dávaj pozor na výber, lebo aj fotografia naznačuje niečo o osobnosti na fotografii"
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter.native="on.mouseenter"
                  @mouseleave.native="on.mouseleave"
                ></v-file-input>
              </template>
              <span>
                Fotografia - sem vlož svoju fotografiu, ktorá sa bude zobrazovať pri tvojom profile. Dávaj pozor na výber, lebo aj fotografia naznačuje niečo o osobnosti na fotografii
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <v-btn
              @click="updateUserPhoto"
              color="primary"
            >Nahrať novú fotografiu</v-btn>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <p>
              Prvú overovaciu platbu zasielaj zo svojho účtu, ktorý si uviedol pri registrácii ({{ form.bank_account }}). Ostatné platby
              môžu byť odoslané aj z iných účtov.
            </p>

            <p>
              Platby zasielaj na bankový účet:
              <strong>SK4983300000004111441114</strong>, vždy použi variabilný
              symbol: <strong>{{ form.id }}</strong>, je to tvoje unikátne ID.
            </p>
            <p>
              Následne sa platba automaticky do niekoľkých pracovných dní
              spáruje a tu uvidíš celkovú sumu prijatých finančných prostriedkov.
            </p>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >Celkovo sme prijali
            <strong>{{ form.bank_transfers_total }} €</strong>.<br /> Celkovo sme z
            Tvojho účtu prijali
            <strong v-if="form.bank_transfers_total_own">{{ form.bank_transfers_total_own }} €</strong>
            <strong v-else>0 €</strong>.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-timeline :dense="$vuetify.breakpoint.smAndDown">
              <v-timeline-item
                color="purple lighten-2"
                fill-dot
                right
              >
                <v-card>
                  <v-card-title
                    class="purple lighten-2"
                    style="word-break: break-word;"
                  >
                    <h2 class="text-h5 white--text font-weight-light">
                      Chcem voliť kandidáta do NR SR
                    </h2>
                  </v-card-title>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        md="10"
                      >
                        Ak chceš voliť, musíme overiť tvoju identitu prostredníctvom prijatia platby minimálne vo výške {{ getSettings.hladina_volic }} € z tvojho bankového účtu uvedeného v tvojom profile pri registrácii.
                        Ak nás chceš podporiť aj viac, môžeš poslať aj viac. Budeme len radi. Ďakujeme.
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <v-btn
                          :disabled="!form.verified_bank"
                          to="/list"
                          :dark="form.verified_bank"
                          color="purple lighten-2"
                          small
                        >Voliť</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>

              <v-timeline-item
                color="amber lighten-1"
                fill-dot
                left
                small
              >
                <v-card>
                  <v-card-title
                    class="amber lighten-1 justify-end"
                    style="word-break: break-word;"
                  >
                    <h2 class="text-h5 mr-4 white--text font-weight-light">
                      Chcem kandidovať za poslanca do NR SR
                    </h2>
                  </v-card-title>
                  <v-container>
                    <v-row>
                      <v-col cols="9">
                        Ak chceš kandidovať, musíme pod tvojím ID (VS) prijať platbu minimálne vo výške {{ getSettings.hladina_poslanec }} €.
                        Táto výška je stanovená preto aby sa nehlásili za kandidátov osoby, ktoré by kandidatúru nemysleli úplne vážne.
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <v-btn
                          :disabled="!form.verified_poslanec"
                          to="/candidate"
                          color="amber lighten-1"
                          :dark="form.verified_poslanec"
                          small
                        >Žiadosť</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>

              <v-timeline-item
                color="cyan lighten-1"
                fill-dot
                right
              >
                <v-card>
                  <v-card-title
                    class="cyan lighten-1"
                    style="word-break: break-word;"
                  >
                    <h2 class="text-h6 white--text font-weight-light">
                      Chcem sa stať plnohodnotným členom Nášho Hnutia
                    </h2>
                  </v-card-title>
                  <v-container>
                    <v-row>
                      <v-col cols="9">
                        Ak sa chceš stať členom Nášho Hnutia, budeme radi každému šikovnému a charakternému človeku.
                        Takisto pomôžeš našej činnosti pravidelnými platbami členských príspevkov. Môžeš ich platiť mesačne vo výške {{ getSettings.hladina_clen }} € alebo ročne {{ Math.round(getSettings.hladina_clen * 12)}} €.
                        Ostatné podrobnosti nájdeš pri registrácii, kde budeme od teba ako člena potrebovať ešte ďalšie informácie a súhlasy.
                        Dúfame, že budeme hnutím, ktoré bude mať čo najrýchlejšie čo najviac členov. Pre podanie žiadosti je potrebné prijať platby v kumulatívnej minimálnej výške {{ getSettings.hladina_clen }} €. Pre záujem o registráciu klikni na ŽIADOSŤ.
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <v-btn
                          :disabled="!form.verified_clen"
                          to="/member"
                          color="cyan lighten-1"
                          :dark="form.verified_clen"
                          small
                        >Žiadosť</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>

              <v-timeline-item
                color="red lighten-1"
                fill-dot
                left
                small
              >
                <v-card>
                  <v-card-title
                    class="red lighten-1 justify-end"
                    style="word-break: break-word;"
                  >
                    <h2 class="text-h6 mr-4 white--text font-weight-light">
                      Chcem zastávať exekutívnu funkciu za Naše Hnutie
                    </h2>
                  </v-card-title>
                  <v-container>
                    <v-row>
                      <v-col cols="auto">
                        Túto sekciu pripravujeme. Pôjde o osoby, ktoré budú chcieť pôsobiť v exekutíve napríklad vo vláde alebo na ministerstvách ako vysokí riadiaci úradníci.
                        Toto ale začne dávať zmysel keď Naše Hnutie bude mať dostatočnú podporu u voličov v SR. Aj tu Naše Hnutie ponúkne personálne vybavenie exekutívy ešte pred voľbami. Tak bude jasné kto a s akým programom bude chcieť spravovať daný rezort.
                        <!-- {{ settings.hladina_exekutivna_funkcia }} € -->
                      </v-col>
                      <!-- <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <v-btn
                          v-if="!form.staff_id"
                          :disabled="!form.verified_exekutivna_funkcia"
                          @click="createStaff()"
                          color="red lighten-1"
                          :dark="form.verified_exekutivna_funkcia"
                          small
                        >Poslať žiadosť</v-btn>
                        <v-btn
                          v-else
                          disabled
                          color="red lighten-1"
                          small
                        >Žiadosť odoslaná</v-btn>
                      </v-col> -->
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar
      v-model="snackbar_saved"
      color="success"
      :timeout="10000"
    >
      Pozor. Ak si nevyplnil svoj profil pravdivo, korektne a správne, môže sa stať, že nebudeš môcť využívať všetky funkcie tejto platformy. Skontroluj si pre istotu správnosť údajov v tvojom profile. Uložené.
      <v-btn
        text
        @click="snackbar_saved = false"
      >
        Zavrieť
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbar_error"
      color="error"
      :timeout="10000"
    >
      Chyba
    </v-snackbar>

  </div>
</template>

<script>
import Vue from 'vue'

import { APIService } from "@/http/APIService.js";
import { mapGetters } from "vuex";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { required, regex, confirmed, email, min } from 'vee-validate/dist/rules'
import VueMask from 'v-mask'

Vue.use(VueMask);

const apiService = new APIService();

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} nemôže byť prázdne',
})

extend('phone', {
  ...regex,
  message: 'Číslo zadávaj v medzinárodnom tvare (+421) bez medzier',
})

extend('iban', {
  ...regex,
  message: 'IBAN zadávaj v tvare s medzerami. Napr. SK31 1200 0000 1987 4263 7541',
})

extend('email', {
  ...email,
  message: 'Emailová adresa nie je korektná. Napr. info@nasehnutie.sk',
})

extend('confirmed', {
  ...confirmed,
  message: 'Znovu zadaná hodnota sa nezhoduje',
})

extend('min', {
  ...min,
  message: 'Minimálna dĺžka hesla je 10 znakov',
})


export default {
  props: {
    source: String,
  },
  data() {
    return {
      show1: false,
      show2: false,

      loading: false,
      drawer: null,
      tab: 0,

      snackbar_saved: false,
      snackbar_error: false,
      search_form_address_town: "",

      settings: {},

      photo_file: null,

      form: {
        username: "",
        password: "",
        password2: "",
        email: "",
        email2: "",
        first_name: "",
        last_name: "",
        bank_account: "",
        bank_account2: "",
        phone_number: "",
        phone_number2: "",
        gender: "",
      },

      selects: [
        { text: "Muž", value: "MAN" },
        { text: "Žena", value: "WOMAN" },
      ],

      searchTown: null,
      itemsTown: []
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("settings", ["getSettings"]),

    getTabRoute() {
      return this.$route.params.tabID;
    }
  },

  mounted: function () {
    this.getMe();
    this.tab = parseInt(this.getTabRoute);
  },

  watch: {
    searchTown(val) {
      this.searchTownApi(val);
    }
  },

  methods: {
    townChanged(event) {
      if (event.okres.name)
        this.form.address_okres = event.okres.name;
      if (event.okres.kraj.name)
        this.form.address_kraj = event.okres.kraj.name;
      if (event.okres.kraj.krajina.name)
        this.form.address_country = event.okres.kraj.krajina.name;
    },

    searchTownApi(val) {
      apiService
        .searchObec(val)
        .then((response) => {
          this.itemsTown = response;
        });
    },

    searchTownApiFirst(val) {
      apiService
        .searchObec(val)
        .then((response) => {
          this.itemsTown = response;
          this.search_form_address_town = this.itemsTown[0];
          this.$refs.observer.validate();
        });
    },

    createStaff() {
      apiService.createStaff({ user: this.form.id }).then(() => {
        this.getMe();
      });
    },

    getMe() {
      apiService.getMe().then((response) => {
        this.form = response;

        this.form.bank_account2 = this.form.bank_account;
        this.form.password = null;
        this.form.phone_number2 = this.form.phone_number;
        this.form.email2 = this.form.email;

        this.loading = false;
        this.searchTownApiFirst(this.form.address_town);
      });
    },

    update() {
      delete this.form.photo;
      this.$refs.observer.validate();
      this.loading = true;
      // if (!this.form.password)
      //   delete this.form.password;

      if (!this.form.bank_account)
        this.form.bank_account = null;

      if (!this.form.password)
        delete this.form.password;

      if (this.form.address_town && this.form.address_town.name)
        this.form.address_town = this.form.address_town.name;
      else if (this.search_form_address_town)
        this.form.address_town = this.search_form_address_town.name;


      apiService.patchUser(this.form, this.form.id).then((response) => {
        this.form = response;

        this.form.bank_account = this.form.bank_account.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        this.form.bank_account2 = this.form.bank_account;
        this.form.password = null;
        this.form.phone_number2 = this.form.phone_number;
        this.form.email2 = this.form.email;

        this.loading = false;
        this.snackbar_saved = true;
      });
    },


    updateUserPhoto() {
      this.loading = true;

      let formData = new FormData();

      if (this.photo_file instanceof File)
        formData.append("photo", this.photo_file);
      else
        formData.append("photo", "");

      formData.append("id", this.form.id);

      apiService.patchUser(formData, this.form.id).then((response) => {
        this.form = response;
        this.snackbar_saved = true;
        this.getMe();
      });
    }
  },
};
</script>